.root {
  background-image: url(../../utilities/assets/png/smoke_effect.png);
  background-repeat: no-repeat;
  flex: 1;
  display: flex;
  padding: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 2;
}
.btn {
  width: 5.5em;
  padding: 10px 15px;
  border: none;
  background-color: yellow;
  color: #000;
  text-align: center;
  font-family: "Montserrat";
}

.content p {
  font-family: "Montserrat";
}

.content h2 {
  font-family: "Poppins";
  letter-spacing: 0.06em;
  z-index: 100;
}
.image {
  flex: 1;
}
.image img {
  position: absolute;
  max-width: 30%;
  height: 200px;
  bottom: 10px;
  object-fit: scale-down;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .root {
    padding: 30px;
  }
  .content {
    margin-right: 10px;
  }
  .image img {
    max-width: 120px;
    bottom: 10px;
    right: 5%;
    position: absolute;
    filter: blur(0.02em);
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .root {
    padding: 30px;
  }
  .content {
    margin-right: 10px;
  }
  .image img {
    max-width: 180px;
    right: 5%;
    position: absolute;
    /* filter: blur(0.02em); */
  }
  .content h2 {
    width: 75%;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
