.btn {
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #000000;
  cursor: pointer;
  font-size: 0.7em;
}
.btn:hover {
  background-color: #000000;
  color: #ffffff;
}
