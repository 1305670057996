.nav {
  display: flex;
  justify-content: center;
  padding: 30px 40px;
  background-color: #000000;
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 0.85em;
}

.logo {
  height: 70px;
  object-fit: scale-down;
  flex: 1;
}

ul li {
  list-style: none;
}
.pointer {
  cursor: pointer;
}

.menuLabel,
.menuActionIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.menuLabel {
  flex: 5;
  gap: 50px;
}

a {
  text-decoration: none;
  color: #e7e3e3;
  padding: 0 5px;
  transition: color 0.2s ease;
  position: relative;
}

.menuLabel a::before,
.menuLabel a::after {
  content: "";
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.menuLabel a::before {
  left: -5px;
  content: "[";
}

.menuLabel a::after {
  right: -5px;
  content: "]";
}

.menuLabel a:hover,
.active {
  color: #fa8d08;
}

.menuLabel a:hover::before,
.menuLabel a:hover::after,
.menuLabel .active::before,
.menuLabel .active::after {
  opacity: 1;
}

.menuActionIcons {
  font-size: 25px;
}

.cartBag {
  position: relative;
  cursor: pointer;
}
.cartCounter {
  position: absolute;
  top: -18px;
  right: -18px;
  font-size: 12px;
  background-color: #f8d5b5;
  padding: 5px;
  min-width: 15px;
  min-height: 10px;
  text-align: center;
  border-radius: 100%;
  font-weight: bold;
  font-family: "Noto Sans JP";
  color: #000;
  letter-spacing: 0.09em;
}
.navDrawer,
.closeNav,
.mobileOnly {
  display: none;
}
.lg {
  display: inline-block;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .navDrawer,
  .mobileOnly,
  .closeNav {
    display: inline-block;
  }
  .lg {
    display: none;
  }
  .nav {
    justify-content: space-between;
    position: relative;
  }
  .logo {
    height: 55px;
    position: absolute;
    left: 30px;
    top: 18px;
  }
  .closeNav {
    position: absolute;
    z-index: 1006;
    right: 20px;
    top: 20px;
    color: #fff;
    font-size: 40px;
  }

  ul.menuLabel {
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.94);
    z-index: 102;
    left: 0;
    top: 0;
    padding: 100px 0 0 30px;
    gap: 30px;
  }

  .menuLabel a::before,
  .menuLabel a::after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  .menuLabel li {
    font-size: 18px;
  }
  .menuActionIcons {
    justify-content: flex-end;
    font-size: 25px;
  }
  .hide {
    visibility: hidden;
  }
  .show {
    visibility: visible;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
