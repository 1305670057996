.action {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #888;
  width: 100px;
  height: 20px;
  border-radius: 2px;
  padding: 5px 0px 2px 0;
}
.btnAction {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.counter {
  width: 60px;
  text-align: center;
  border: none;
  background-color: transparent;
  font-weight: bold;
}
.counter:focus {
  outline: none;
}
.btnAction,
.counter {
  font-size: 0.9em;
}
