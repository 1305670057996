@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&family=Noto+Sans+JP:wght@500&family=Poppins:wght@500&display=swap);
* {
  margin: 0;
  padding: 0;
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* For Firefox */
/* Note: Firefox currently only supports scrollbar color customization */
/* To make it smaller, you can only change the color */
/* You can adjust the color to make it less noticeable */
/* You may also need to use !important to override default browser styles */

/* Example styles for Firefox scrollbar */
* {
  scrollbar-color: #888 transparent;
  scrollbar-width: thin;
}

.Header_header__3ZEno {
  position: relative;
  /* width: 100%; */
  height: 0;
  padding-bottom: 30%;
  overflow: hidden;
}

.Header_img__1ZNGt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Offers_root__2mXYB {
  background-image: url(/static/media/smoke_effect.c4b925e6.png);
  background-repeat: no-repeat;
  flex: 1 1;
  display: flex;
  padding: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
}
.Offers_content__1iEA5 {
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  gap: 25px;
  flex: 2 1;
}
.Offers_btn__3-w5W {
  width: 5.5em;
  padding: 10px 15px;
  border: none;
  background-color: yellow;
  color: #000;
  text-align: center;
  font-family: "Montserrat";
}

.Offers_content__1iEA5 p {
  font-family: "Montserrat";
}

.Offers_content__1iEA5 h2 {
  font-family: "Poppins";
  letter-spacing: 0.06em;
  z-index: 100;
}
.Offers_image__1ELKQ {
  flex: 1 1;
}
.Offers_image__1ELKQ img {
  position: absolute;
  max-width: 30%;
  height: 200px;
  bottom: 10px;
  object-fit: scale-down;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .Offers_root__2mXYB {
    padding: 30px;
  }
  .Offers_content__1iEA5 {
    margin-right: 10px;
  }
  .Offers_image__1ELKQ img {
    max-width: 120px;
    bottom: 10px;
    right: 5%;
    position: absolute;
    -webkit-filter: blur(0.02em);
            filter: blur(0.02em);
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Offers_root__2mXYB {
    padding: 30px;
  }
  .Offers_content__1iEA5 {
    margin-right: 10px;
  }
  .Offers_image__1ELKQ img {
    max-width: 180px;
    right: 5%;
    position: absolute;
    /* filter: blur(0.02em); */
  }
  .Offers_content__1iEA5 h2 {
    width: 75%;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}

.Container_row__13CEf,
.Container_column__245yG {
  padding: 20px 10%;
  grid-gap: 20px;
  gap: 20px;
}

.Container_rowWrap__3ae5f {
  grid-gap: 20px;
  gap: 20px;
}
.Container_row__13CEf,
.Container_rowWrap__3ae5f {
  display: flex;
}
.Container_rowWrap__3ae5f {
  flex-wrap: wrap;
}

.Container_column__245yG {
  display: flex;
  flex-direction: column;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .Container_row__13CEf {
    flex-direction: column;
    padding: 20px;
    grid-gap: 20px;
    gap: 20px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Container_row__13CEf {
    flex-direction: column;
    padding: 20px;
    grid-gap: 20px;
    gap: 20px;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}

.Product_product__30drb {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 10px;
  width: 22%;
}
.Product_section__2kUfE {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-family: "Montserrat";
  padding: 5px 5px 15px 5px;
}
.Product_img__3iEXt {
  height: 200px;
  object-fit: scale-down;
}
.Product_title__2Ispk {
  text-align: center;
  line-height: 1.3em;
}
.Product_saleLabel__1HktA,
.Product_saleLabelEmpty__3La6a {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f6d884;
  font-size: 12px;
  font-family: "Montserrat";
}
.Product_saleLabelEmpty__3La6a {
  background-color: transparent;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .Product_product__30drb {
    width: 100%;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Product_product__30drb {
    width: 45%;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) and (max-width: 1500px) {
  .Product_product__30drb {
    width: 29%;
  }
}

.AddToCart_btn__2Q9N2 {
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #000000;
  cursor: pointer;
  font-size: 0.7em;
}
.AddToCart_btn__2Q9N2:hover {
  background-color: #000000;
  color: #ffffff;
}

.ProductCounter_action__17bvq {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #888;
  width: 100px;
  height: 20px;
  border-radius: 2px;
  padding: 5px 0px 2px 0;
}
.ProductCounter_btnAction__1KsGR {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.ProductCounter_counter__24V3b {
  width: 60px;
  text-align: center;
  border: none;
  background-color: transparent;
  font-weight: bold;
}
.ProductCounter_counter__24V3b:focus {
  outline: none;
}
.ProductCounter_btnAction__1KsGR,
.ProductCounter_counter__24V3b {
  font-size: 0.9em;
}

.Price_amount__2nbMK {
  color: #872323;
  font-size: 1.1em;
}
.Price_amount__2nbMK span {
  text-decoration: line-through;
  padding-right: 10px;
  font-size: 0.8em;
  color: #999;
}
/* .amount span {
  text-decoration: line-through;
  transform: rotate(-45deg);
  padding-right: 10px;
  color: #999;
} */

.Cart_cart__naaGv {
  position: fixed;
  width: 350px;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #f3f3f3;
  z-index: 101;
  font-family: "Montserrat";
  border-left: 1px solid #eae9e9;
}
.Cart_top__2oQAe,
.Cart_bottom__2K_YY,
.Cart_content__31CBp {
  padding: 15px;
  width: 100%;
}
.Cart_content__31CBp {
  overflow-y: auto;
  height: 60%;
  width: 90%;
}
.Cart_top__2oQAe,
.Cart_bottom__2K_YY {
  background-color: #ffffff;
}

.Cart_bottom__2K_YY {
  position: absolute;
  bottom: 0;
}

.Cart_top__2oQAe {
  display: flex;
  font-size: 18px;
  align-items: center;
}
.Cart_icon__2Jrwu,
.Cart_btnClose__26tZM {
  font-size: 35px;
  padding-right: 5px;
  background-color: transparent;
}
.Cart_label__3VdK8,
.Cart_price__2G1m7 {
  display: flex;
  align-items: center;
  flex: 4 1;
  justify-content: center;
  padding-bottom: 15px;
}
.Cart_price__2G1m7 {
  justify-content: flex-end;
  padding-right: 10%;
  font-weight: bold;
}
.Cart_label__3VdK8 {
  font-size: 0.9em;
}
.Cart_row__3rXM9 {
  display: flex;
  margin: 20px 0;
}
.Cart_row__3rXM9 p:nth-child(1) {
  font-size: 0.8em;
}
.Cart_checkout__7AkFM {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.Cart_btnClose__26tZM,
.Cart_checkoutBtn__xbaw- {
  font-family: "Montserrat";
  cursor: pointer;
  border: none;
}
.Cart_checkoutBtn__xbaw- {
  border: 2px solid #000;
  color: #000;
  padding: 10px 40px;
  background-color: transparent;
}
.Cart_checkoutBtn__xbaw-:hover {
  background-color: #000;
  color: #fff;
}

.CartItem_cartItem__1fCZG {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #fff;
  position: relative;
}
.CartItem_row__2NBbS {
  display: flex;
  margin-top: 10%;
}
.CartItem_content__2IiED {
  flex: 4 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CartItem_amount__3eCgM {
  margin-bottom: 20px;
}
.CartItem_img__2HAhi {
  width: 80px;
}
.CartItem_deleteBtn__1ew3p {
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: rgb(164, 37, 37);
  position: absolute;
  right: 5%;
  cursor: pointer;
}

.Home_heading__1gl50 {
  font-family: "Poppins";
  color: #c97106;
  padding-bottom: 10px;
  border-bottom: 1px solid #888;
}

.Menu_nav__3u5Ur {
  display: flex;
  justify-content: center;
  padding: 30px 40px;
  background-color: #000000;
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 0.85em;
}

.Menu_logo__3GEro {
  height: 70px;
  object-fit: scale-down;
  flex: 1 1;
}

ul li {
  list-style: none;
}
.Menu_pointer__1nX25 {
  cursor: pointer;
}

.Menu_menuLabel__2U9SE,
.Menu_menuActionIcons__1Its3 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  flex: 1 1;
}

.Menu_menuLabel__2U9SE {
  flex: 5 1;
  grid-gap: 50px;
  gap: 50px;
}

a {
  text-decoration: none;
  color: #e7e3e3;
  padding: 0 5px;
  transition: color 0.2s ease;
  position: relative;
}

.Menu_menuLabel__2U9SE a::before,
.Menu_menuLabel__2U9SE a::after {
  content: "";
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.Menu_menuLabel__2U9SE a::before {
  left: -5px;
  content: "[";
}

.Menu_menuLabel__2U9SE a::after {
  right: -5px;
  content: "]";
}

.Menu_menuLabel__2U9SE a:hover,
.Menu_active__3RZbM {
  color: #fa8d08;
}

.Menu_menuLabel__2U9SE a:hover::before,
.Menu_menuLabel__2U9SE a:hover::after,
.Menu_menuLabel__2U9SE .Menu_active__3RZbM::before,
.Menu_menuLabel__2U9SE .Menu_active__3RZbM::after {
  opacity: 1;
}

.Menu_menuActionIcons__1Its3 {
  font-size: 25px;
}

.Menu_cartBag__3n1ll {
  position: relative;
  cursor: pointer;
}
.Menu_cartCounter__2q0Cc {
  position: absolute;
  top: -18px;
  right: -18px;
  font-size: 12px;
  background-color: #f8d5b5;
  padding: 5px;
  min-width: 15px;
  min-height: 10px;
  text-align: center;
  border-radius: 100%;
  font-weight: bold;
  font-family: "Noto Sans JP";
  color: #000;
  letter-spacing: 0.09em;
}
.Menu_navDrawer__x4UAv,
.Menu_closeNav__3r3g3,
.Menu_mobileOnly__30m1B {
  display: none;
}
.Menu_lg__3pmWJ {
  display: inline-block;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .Menu_navDrawer__x4UAv,
  .Menu_mobileOnly__30m1B,
  .Menu_closeNav__3r3g3 {
    display: inline-block;
  }
  .Menu_lg__3pmWJ {
    display: none;
  }
  .Menu_nav__3u5Ur {
    justify-content: space-between;
    position: relative;
  }
  .Menu_logo__3GEro {
    height: 55px;
    position: absolute;
    left: 30px;
    top: 18px;
  }
  .Menu_closeNav__3r3g3 {
    position: absolute;
    z-index: 1006;
    right: 20px;
    top: 20px;
    color: #fff;
    font-size: 40px;
  }

  ul.Menu_menuLabel__2U9SE {
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.94);
    z-index: 102;
    left: 0;
    top: 0;
    padding: 100px 0 0 30px;
    grid-gap: 30px;
    gap: 30px;
  }

  .Menu_menuLabel__2U9SE a::before,
  .Menu_menuLabel__2U9SE a::after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  .Menu_menuLabel__2U9SE li {
    font-size: 18px;
  }
  .Menu_menuActionIcons__1Its3 {
    justify-content: flex-end;
    font-size: 25px;
  }
  .Menu_hide__qdhlJ {
    visibility: hidden;
  }
  .Menu_show__1As72 {
    visibility: visible;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}

.Footer_footerBottom__1ldlW {
  background-color: #000;
  color: #fff;
  font-size: 0.98em;
  padding-top: 30px;
  font-family: "Montserrat";
}
.Footer_item__c471h {
  flex: 1 1;
}
.Footer_securePayment__2tscG {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Footer_icon__21RpD {
  font-size: 6em;
  color: #cdedd6;
  margin-bottom: -20px;
}
.Footer_mb3__35KNX {
  margin-bottom: 30px;
  color: #ccd6fc;
  font-weight: 200px;
  font-family: "Poppins";
}
.Footer_item__c471h p {
  margin-bottom: 10px;
}

.Footer_link__3ZCeU {
  margin-left: -5px;
  display: block;
  margin-bottom: 10px;
  color: #ffedb4;
}
.Footer_center__1s_ZE {
  text-align: center;
  background-color: #ccc;
  color: #000000;
  font-size: 14px;
  padding: 10px 0;
}

.Footer_row__NPxM_ {
  padding: 20px 10%;
  grid-gap: 20px;
  gap: 20px;

  display: flex;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .Footer_footerBottom__1ldlW {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .Footer_mb3__35KNX {
    margin: 30px 0 20px 0;
  }
  .Footer_center__1s_ZE {
    display: flex !important;
    width: 100%;
    justify-content: center;
  }
  .Footer_row__NPxM_ {
    flex-direction: column;
    padding: 20px;
    grid-gap: 20px;
    gap: 20px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}

.ProductDetails_root__3UgkR {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: #fff;
  width: 50%;
  top: 20vh;
  left: 24%;
  padding: 25px 40px 40px 40px;
  position: fixed;
  display: flex;
  font-family: "Montserrat";
  z-index: 100;
}
.ProductDetails_btnClose__30wWv {
  background-color: transparent;
  font-size: 2em;
  border: none;
  text-align: right;
  margin-bottom: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.ProductDetails_content__1zgLd {
  flex: 2 1;
  padding-left: 30px;
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.ProductDetails_image__1ch0a {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ProductDetails_img__1kz9B {
  height: 200px;
  object-fit: scale-down;
}

.ProductDetails_title__1x4XG {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
}
.ProductDetails_description__3cVM2 {
  font-size: 0.9em;
  line-height: 25px;
  margin-bottom: 20px;
}

.ProductDetails_actionGroup__2IlYm {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .ProductDetails_root__3UgkR {
    top: 3vh;
    padding: 40px 0;
    left: 0;
    width: 95%;
    flex-direction: column;
    margin: 10px;
  }
  .ProductDetails_image__1ch0a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ProductDetails_content__1zgLd {
    padding: 0 5%;
    margin-top: 1em;
  }
  .ProductDetails_header__1QhL1 {
    text-align: center;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ProductDetails_root__3UgkR {
    width: 60%;
    left: 18%;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) and (max-width: 1500px) {
}


ul.BreadCrumbs_breadcrumbs___rNgm {
  display: flex;
}

ul.BreadCrumbs_breadcrumbs___rNgm li {
  padding-right: 5px;
  font-size: 1.1em;
  font-family: "Montserrat";
}
ul.BreadCrumbs_breadcrumbs___rNgm li a {
  color: #d3780f;
}
ul.BreadCrumbs_breadcrumbs___rNgm li a::after {
  content: "/";
  padding-left: 10px;
}

.Search_search__2WI17 {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 13%;
  right: 10%;
  width: 400px;
  height: 130px;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
}
.Search_searchField__26_ho {
  font-family: "Montserrat";
  height: 55px;
  width: 80%;
  text-indent: 20px;
  font-size: 1em;
  border: 1px solid #000;
}
.Search_go__3IAPa {
  position: absolute;
  right: 12%;
  padding: 10px 15px;
  background-color: #000;
  color: #fff;
  height: 30px;
  border: none;
  border-radius: 50px;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: bold; */
  font-family: "Poppins";
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .Search_search__2WI17 {
    top: 10%;
    right: 3%;
    width: 350px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}

.About_root__3QFOU {
  display: flex;
  min-height: 60vh;
}
.About_content__5pV5F {
  width: 700px;
  font-size: 1.1em;
  line-height: 1.6em;
  padding-top: 5%;
  font-family: "Montserrat";
}
.About_heading__BtDwt,
.About_title__2g5CZ {
  margin-bottom: 20px;
  font-family: "Noto Sans JP";
}
.About_heading__BtDwt {
  color: #3b2d1c;
  font-style: italic;
}
.About_title__2g5CZ {
  color: #aaa;
  font-size: 1.3em;
}
.About_img__2muCR {
  padding: 5% 10%;
}
.About_img__2muCR img {
  height: 300px;
  width: 300px;
  border-radius: 60%;
  object-fit: cover;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .About_root__3QFOU {
    flex-direction: column;
  }
  .About_content__5pV5F {
    width: 100%;
  }
  .About_img__2muCR {
    padding: 10%;
  }
  .About_img__2muCR img {
    height: 250px;
    width: 250px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}

.Contact_root__9vUpU {
  padding: 3% 28%;
}
.Contact_formGroup__2nTRN {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  margin-bottom: 20px;
}
.Contact_formGroup__2nTRN input,
.Contact_formGroup__2nTRN textarea {
  height: 45px;
  border: 2px solid #000;
  text-indent: 10px;
}
.Contact_formGroup__2nTRN label {
  margin-bottom: 5px;
  color: #999;
  font-size: 0.8em;
}
.Contact_formGroup__2nTRN textarea {
  height: 100px;
  padding-top: 10px;
}
.Contact_root__9vUpU button {
  display: flex;
  padding: 10px 40px;
  border: 2px solid #000;
  background-color: #000;
  color: #fff;
}
/* Phone */
@media only screen and (max-width: 767px) {
  .Contact_root__9vUpU {
    padding: 0;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Contact_root__9vUpU {
    padding: 3% 20%;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}

.Checkout_row__R0jHy {
  display: flex;
  padding-top: 30px;
  grid-gap: 10%;
  gap: 10%;
  min-height: 60vh;
  justify-content: space-evenly;
  font-family: "Montserrat";
}
.Checkout_row__R0jHy div {
  width: 100%;
}
.Checkout_formGroup__3E9n- {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  margin-bottom: 20px;
}
.Checkout_formGroup__3E9n- input,
.Checkout_formGroup__3E9n- textarea {
  height: 45px;
  border: 2px solid #000;
  text-indent: 10px;
}
.Checkout_formGroup__3E9n- label {
  margin-bottom: 5px;
  color: #999;
  font-size: 0.8em;
}
.Checkout_formGroup__3E9n- textarea {
  height: 100px;
  padding-top: 10px;
}
.Checkout_heading__beyKe {
  margin-bottom: 30px;
}
ul.Checkout_orderSummary__bZbXB {
  list-style: none;
  border: 1px solid #aaa;
}
ul.Checkout_orderSummary__bZbXB li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #aaa;
  padding: 15px;
}
ul.Checkout_orderSummary__bZbXB li:last-child {
  border-bottom: none;
}
ul.Checkout_orderSummary__bZbXB li div:last-child {
  text-align: right;
}
ul.Checkout_orderSummary__bZbXB li button {
  width: 100%;
  padding: 20px 0;
  background-color: #000;
  color: #fff;
  cursor: pointer;
}
ul.Checkout_orderSummary__bZbXB li button:hover {
  border: 2px solid #000;
  color: #000;
  background-color: transparent;
}
/* Phone */
@media only screen and (max-width: 767px) {
  .Checkout_row__R0jHy {
    flex-direction: column;
  }
  div .Checkout_formGroup__3E9n-:last-child {
    margin-bottom: 50px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}

