.search {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 13%;
  right: 10%;
  width: 400px;
  height: 130px;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
}
.searchField {
  font-family: "Montserrat";
  height: 55px;
  width: 80%;
  text-indent: 20px;
  font-size: 1em;
  border: 1px solid #000;
}
.go {
  position: absolute;
  right: 12%;
  padding: 10px 15px;
  background-color: #000;
  color: #fff;
  height: 30px;
  border: none;
  border-radius: 50px;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: bold; */
  font-family: "Poppins";
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .search {
    top: 10%;
    right: 3%;
    width: 350px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
