.footerBottom {
  background-color: #000;
  color: #fff;
  font-size: 0.98em;
  padding-top: 30px;
  font-family: "Montserrat";
}
.item {
  flex: 1;
}
.securePayment {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.icon {
  font-size: 6em;
  color: #cdedd6;
  margin-bottom: -20px;
}
.mb3 {
  margin-bottom: 30px;
  color: #ccd6fc;
  font-weight: 200px;
  font-family: "Poppins";
}
.item p {
  margin-bottom: 10px;
}

.link {
  margin-left: -5px;
  display: block;
  margin-bottom: 10px;
  color: #ffedb4;
}
.center {
  text-align: center;
  background-color: #ccc;
  color: #000000;
  font-size: 14px;
  padding: 10px 0;
}

.row {
  padding: 20px 10%;
  gap: 20px;

  display: flex;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .footerBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .mb3 {
    margin: 30px 0 20px 0;
  }
  .center {
    display: flex !important;
    width: 100%;
    justify-content: center;
  }
  .row {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
