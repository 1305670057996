.root {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: #fff;
  width: 50%;
  top: 20vh;
  left: 24%;
  padding: 25px 40px 40px 40px;
  position: fixed;
  display: flex;
  font-family: "Montserrat";
  z-index: 100;
}
.btnClose {
  background-color: transparent;
  font-size: 2em;
  border: none;
  text-align: right;
  margin-bottom: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.content {
  flex: 2;
  padding-left: 30px;
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.image {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.img {
  height: 200px;
  object-fit: scale-down;
}

.title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
}
.description {
  font-size: 0.9em;
  line-height: 25px;
  margin-bottom: 20px;
}

.actionGroup {
  display: flex;
  gap: 20px;
  align-items: center;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .root {
    top: 3vh;
    padding: 40px 0;
    left: 0;
    width: 95%;
    flex-direction: column;
    margin: 10px;
  }
  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content {
    padding: 0 5%;
    margin-top: 1em;
  }
  .header {
    text-align: center;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .root {
    width: 60%;
    left: 18%;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) and (max-width: 1500px) {
}
