.row,
.column {
  padding: 20px 10%;
  gap: 20px;
}

.rowWrap {
  gap: 20px;
}
.row,
.rowWrap {
  display: flex;
}
.rowWrap {
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .row {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .row {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
