ul.breadcrumbs {
  display: flex;
}

ul.breadcrumbs li {
  padding-right: 5px;
  font-size: 1.1em;
  font-family: "Montserrat";
}
ul.breadcrumbs li a {
  color: #d3780f;
}
ul.breadcrumbs li a::after {
  content: "/";
  padding-left: 10px;
}
