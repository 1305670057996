* {
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&family=Noto+Sans+JP:wght@500&family=Poppins:wght@500&display=swap");

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* For Firefox */
/* Note: Firefox currently only supports scrollbar color customization */
/* To make it smaller, you can only change the color */
/* You can adjust the color to make it less noticeable */
/* You may also need to use !important to override default browser styles */

/* Example styles for Firefox scrollbar */
* {
  scrollbar-color: #888 transparent;
  scrollbar-width: thin;
}
