.cartItem {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #fff;
  position: relative;
}
.row {
  display: flex;
  margin-top: 10%;
}
.content {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.amount {
  margin-bottom: 20px;
}
.img {
  width: 80px;
}
.deleteBtn {
  background-color: transparent;
  border: none;
  font-size: 22px;
  color: rgb(164, 37, 37);
  position: absolute;
  right: 5%;
  cursor: pointer;
}
