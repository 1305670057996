.root {
  display: flex;
  min-height: 60vh;
}
.content {
  width: 700px;
  font-size: 1.1em;
  line-height: 1.6em;
  padding-top: 5%;
  font-family: "Montserrat";
}
.heading,
.title {
  margin-bottom: 20px;
  font-family: "Noto Sans JP";
}
.heading {
  color: #3b2d1c;
  font-style: italic;
}
.title {
  color: #aaa;
  font-size: 1.3em;
}
.img {
  padding: 5% 10%;
}
.img img {
  height: 300px;
  width: 300px;
  border-radius: 60%;
  object-fit: cover;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .root {
    flex-direction: column;
  }
  .content {
    width: 100%;
  }
  .img {
    padding: 10%;
  }
  .img img {
    height: 250px;
    width: 250px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
