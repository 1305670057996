.product {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 10px;
  width: 22%;
}
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-family: "Montserrat";
  padding: 5px 5px 15px 5px;
}
.img {
  height: 200px;
  object-fit: scale-down;
}
.title {
  text-align: center;
  line-height: 1.3em;
}
.saleLabel,
.saleLabelEmpty {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f6d884;
  font-size: 12px;
  font-family: "Montserrat";
}
.saleLabelEmpty {
  background-color: transparent;
}

/* Phone */
@media only screen and (max-width: 767px) {
  .product {
    width: 100%;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .product {
    width: 45%;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) and (max-width: 1500px) {
  .product {
    width: 29%;
  }
}
