.amount {
  color: #872323;
  font-size: 1.1em;
}
.amount span {
  text-decoration: line-through;
  padding-right: 10px;
  font-size: 0.8em;
  color: #999;
}
/* .amount span {
  text-decoration: line-through;
  transform: rotate(-45deg);
  padding-right: 10px;
  color: #999;
} */
