.row {
  display: flex;
  padding-top: 30px;
  gap: 10%;
  min-height: 60vh;
  justify-content: space-evenly;
  font-family: "Montserrat";
}
.row div {
  width: 100%;
}
.formGroup {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  margin-bottom: 20px;
}
.formGroup input,
.formGroup textarea {
  height: 45px;
  border: 2px solid #000;
  text-indent: 10px;
}
.formGroup label {
  margin-bottom: 5px;
  color: #999;
  font-size: 0.8em;
}
.formGroup textarea {
  height: 100px;
  padding-top: 10px;
}
.heading {
  margin-bottom: 30px;
}
ul.orderSummary {
  list-style: none;
  border: 1px solid #aaa;
}
ul.orderSummary li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #aaa;
  padding: 15px;
}
ul.orderSummary li:last-child {
  border-bottom: none;
}
ul.orderSummary li div:last-child {
  text-align: right;
}
ul.orderSummary li button {
  width: 100%;
  padding: 20px 0;
  background-color: #000;
  color: #fff;
  cursor: pointer;
}
ul.orderSummary li button:hover {
  border: 2px solid #000;
  color: #000;
  background-color: transparent;
}
/* Phone */
@media only screen and (max-width: 767px) {
  .row {
    flex-direction: column;
  }
  div .formGroup:last-child {
    margin-bottom: 50px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
