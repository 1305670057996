.cart {
  position: fixed;
  width: 350px;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #f3f3f3;
  z-index: 101;
  font-family: "Montserrat";
  border-left: 1px solid #eae9e9;
}
.top,
.bottom,
.content {
  padding: 15px;
  width: 100%;
}
.content {
  overflow-y: auto;
  height: 60%;
  width: 90%;
}
.top,
.bottom {
  background-color: #ffffff;
}

.bottom {
  position: absolute;
  bottom: 0;
}

.top {
  display: flex;
  font-size: 18px;
  align-items: center;
}
.icon,
.btnClose {
  font-size: 35px;
  padding-right: 5px;
  background-color: transparent;
}
.label,
.price {
  display: flex;
  align-items: center;
  flex: 4;
  justify-content: center;
  padding-bottom: 15px;
}
.price {
  justify-content: flex-end;
  padding-right: 10%;
  font-weight: bold;
}
.label {
  font-size: 0.9em;
}
.row {
  display: flex;
  margin: 20px 0;
}
.row p:nth-child(1) {
  font-size: 0.8em;
}
.checkout {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.btnClose,
.checkoutBtn {
  font-family: "Montserrat";
  cursor: pointer;
  border: none;
}
.checkoutBtn {
  border: 2px solid #000;
  color: #000;
  padding: 10px 40px;
  background-color: transparent;
}
.checkoutBtn:hover {
  background-color: #000;
  color: #fff;
}
