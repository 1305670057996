.header {
  position: relative;
  /* width: 100%; */
  height: 0;
  padding-bottom: 30%;
  overflow: hidden;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
