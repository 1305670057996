.root {
  padding: 3% 28%;
}
.formGroup {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  margin-bottom: 20px;
}
.formGroup input,
.formGroup textarea {
  height: 45px;
  border: 2px solid #000;
  text-indent: 10px;
}
.formGroup label {
  margin-bottom: 5px;
  color: #999;
  font-size: 0.8em;
}
.formGroup textarea {
  height: 100px;
  padding-top: 10px;
}
.root button {
  display: flex;
  padding: 10px 40px;
  border: 2px solid #000;
  background-color: #000;
  color: #fff;
}
/* Phone */
@media only screen and (max-width: 767px) {
  .root {
    padding: 0;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .root {
    padding: 3% 20%;
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}
